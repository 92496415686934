@import url('https://use.typekit.net/nal4vdu.css');

:root {
  --color-grey-dark: #242424;
  --color-grey-light: #757575;
  --color-purple: #3f2677;
  --type-bold: 700;
  --type-normal: 300;
}

main {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  color: var(--color-grey-dark);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  bottom: 6vh;
  height: 100vh;
}

h1 {
  margin: 0;
  font-size: calc(1rem + 7.5vmin);
  font-family: kautiva-pro, sans-serif;
  font-weight: var(--font-bold);
  font-style: italic;
  text-transform: lowercase;
  color: var(--color-purple);
}

h2 {
  margin: 12px 0 0;
  font-size: calc(1rem + 1.15vmin);
}

h2, .copy {
  font-family: clone-rounded-latin, sans-serif;
  font-weight: var(--font-normal);
  font-style: normal;
}

.copy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0;
  font-size: calc(1rem + 0.33vmin); 
}

.emoji {
  position: relative;
  margin: 0 6px -12px;
  top: -10px;
  font-size: calc(1.5rem + 2vmin); 
}

.tooltip {
  padding-bottom: 2px;
  border-bottom: 1px dotted var(--color-grey-light);
}

.tooltip-component {
  padding: 8px;
  background-color: var(--color-purple);
}

.tooltip-text {
  font-size: calc(1rem + 0.75vmin);
  line-height: 0;
}

.contact {
  display: flex;
  padding-top: 14px;
  border-top: 1px dotted var(--color-grey-light);
}

.contact-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
  min-width: 40px;
  min-height: 40px;
}

.contact-link svg {
  transition: fill ease-out 0.5s;
}

.contact-link:hover svg {
  fill: var(--color-purple);
}

.contact-copy {
  position: absolute;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  overflow: hidden;
}

svg {
  margin-top: -6px;
  width: 24px;
  height: 24px;
  fill: var(--color-grey-light);
}

.spotify {
  position: relative;
  bottom: -3px;
}


@media only screen and (min-width: 767px) {
  .container {
    padding: 0;
    bottom: 10vh;
    left: 10vw;
    max-width: clamp(450px, 60vw, 600px);
  }

  .contact {
    padding-top: 21px;
  }

  .copy {
    margin: 8px 0 24px;
  }

  .emoji {
    margin: 0 6px;
    top: -4px;
  }
}

@media only screen and (min-width: 1079px) {
  .container {
    bottom: 16.5vh;
    left: 13vw;
    max-width: clamp(600px, 60vw, 1000px);
  }

  h2 {
    margin: 21px 0 16px;
  }

  .contact {
    padding-top: 27px;
  }
}
